import { LibraryModel } from './library.model';
/**
 * Model for a user in the context of portal
 */
export class User {
  // email, password, and libraries list are required for login
  email: string;
  password: string;

  // List of libraries to choose in login step
  libraries: LibraryModel[];

  // Full name
  name: string;

  // First name
  firstName: string;

  // Last name
  lastName: string;

  // Alias of the active/loaded library
  loadedLibraryAlias: string;

  // AD username
  activeDirectoryUser: string;

  // portal_user ID
  userId: number;

  // User's timezone
  timezone: string;

  // Roles
  isAuthorInherited: boolean;
  isContributorInherited: boolean;
  isLocalizerInherited: boolean;
  isReviewerInherited: boolean;
  isSupervisorInherited: boolean;
  isAuthor: boolean;
  isContributor: boolean;
  isLocalizer: boolean;
  isReviewer: boolean;
  isSupervisor: boolean;
  isClientAdministrator: boolean;

  // Software edition
  isEnterpriseEdition: boolean;
  isProfessionalEdition: boolean;
  isGroupEdition: boolean;
  isTrialEdition: boolean;

  // Organisation name
  organizationName: string;

  // Date subscription expires
  subscriptionExpiry: Date;

  // Date user should be warned about subscription
  subscriptionWarning: Date;

  // GUID to access frun application (regenerated each login)
  fileManagerGuid: string;

  // Users selected keyboard language, can be empty
  keyboardLanguage: string;

  // Flags to indicate which data has loaded
  hasBasicDetails: boolean;

  /**
   * Copy some details from the DtoUserAccount object
   * @param data The object to copy from
   */
  copyFromDtoUserAccount(data) {
    this.name = data.Name;
    this.firstName = data.FirstName;
    this.lastName = data.LastName;
    this.email = data.Email;
    this.activeDirectoryUser = data.ActiveDirectoryUser;
    this.isAuthorInherited = data.IsAuthorInherited;
    this.isContributorInherited = data.IsContributorInherited;
    this.isLocalizerInherited = data.IsLocalizerInherited;
    this.isReviewerInherited = data.IsReviewerInherited;
    this.isSupervisorInherited = data.IsSupervisorInherited;
    this.userId = data.UserId;
    this.timezone = data.Timezone;
    this.isAuthor = data.IsAuthor;
    this.isContributor = data.IsContributor;
    this.isLocalizer = data.IsLocalizer;
    this.isReviewer = data.IsReviewer;
    this.isSupervisor = data.IsSupervisor;
    this.isClientAdministrator = data.IsClientAdministrator;
    this.fileManagerGuid = data.FileManagerGuid;
    this.keyboardLanguage = data.Keyboard || '';
    this.libraries = data.LibraryList?.map((element) => {
      return {
        id: element.LibraryAlias,
        name: element.LibraryName,
        default: !!element.DefaultLibrary,
      };
    });
    this.isEnterpriseEdition = data.IsEnterpriseEdition;
    this.isProfessionalEdition = data.IsProfessionalEdition;
    this.isGroupEdition = data.IsGroupEdition;
    this.isTrialEdition = data.IsTrialEdition;
    this.organizationName = data.OrganizationName;
    this.subscriptionExpiry = new Date(data.SubscriptionExpiry);
    this.subscriptionWarning = new Date(data.SubscriptionWarning);

    this.hasBasicDetails = true;
  }
}
