import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlagModel } from 'src/app/models/feature-flag-model';
import { UtilService } from '../util/util.service';

interface ConfigData {
  APPLICATIONSBASEURL: string;
  INSTANCE_ID: string;
  AUTHOR_RDP_URL: string;
  SPARK_GATEWAY: string;
  SPARK_SERVER: string;
  SPARK_DOMAIN: string;
  LOGGING_LEVEL: string;
  USERFLOW_TOKEN: string;
  GTM_TOKEN: string;
  CREATE_NEW_ACCOUNT_URL: string;
  HELP_URL: string;
  LOADING_SPINNER_TIMEOUT: number;
  SECURE_COOKIE_ATTRIBUTE: string;
  SAMESITE_COOKIE_ATTRIBUTE: string;
  FEATURE_FLAGS: FeatureFlagModel[];
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  baseUrl = '';
  aitApiEndpoint = '';
  authorRdpUrl = '';
  sparkGateway = '';
  sparkServer = '';
  sparkDomain = '';
  loggingLevel = '';
  userflowToken = '';
  gtmToken = '';
  newAccountUrl = '';
  // existing AIT application URLs
  libraryUrl = '';
  publishingUrl = '';
  reviewsUrl = '';
  signoffUrl = '';
  translationsUrl = '';
  filesUrl = '';
  authorUrl = '';
  settingsUrl = '';
  profileUrl = '';
  recentbookUrl = '';

  /**
   * feature flags
   */
  featureFlags = [] ;

  // Set default values that can be accessed from anywhere in Portal but can be managed here in one place.
  // These are not environment specific so are not loaded from the config file.
  /**
   * Default values that are not environment specific
   */
  default = {
    /**
     * application base url's
     */
    url: {
      aitApiEndpoint: 'aitapi/',
      library: 'Contribute/#library',
      publishing: 'Contribute/#publishing',
      reviews: 'Module/Default.aspx',
      signoff: 'Module/SignOff.aspx',
      translations: 'Module/LmLogin.aspx',
      files: 'files/customizables/ait_autologin.php',
      author: 'Spark',
      settings: 'Contribute/#settings',
      profile: 'Contribute/#settings/users/current',
      recentBook: 'Contribute/#book',
    },

    /**
     * 60 seconds, how often we call the api to refresh the recent items list
     */
    homePageRecentItemsPollInterval: 60000, // 60 seconds

    /**
     * Format of date time strings required by the API
     */
    apiDateTimeFormat: 'yyyy-mm-dd hh:MM:ss',

    /**
     * Get recent items that are X days old
     */
    homePageRecentItemsAge: 31, // 31 days
    recentItemsDateTimeFormat: 'mmm d, yyyy h:MMtt',
    shortDateFormat: 'dd-mmm-yyyy',

    /**
     * Subscription expiry warning
     */
    subscriptionExpiryWarningDays: 30, // 30 days
    subscriptionExpiryCriticalWarningDays: 7, // 7 days
    subscriptionExpiryDateFormat: 'mmm d, yyyy',
    subscriptionExpiryGraceDays: 30, // 30 days

    /**
     * 1 minutes, how often we call the api to check for valid token
     */
    autoLogoutPollInterval: 60000, // 1 minutes

    /**
     * Number of days for locale cookie to expire
     */
    localeCookieExpiryDays: 7,

    /**
     * Number of seconds TTL for cookie that stores the URL to redirect to after a successful SSO login
     */
    redirectCookieExpirySeconds: 300,

    /**
     * how often we check if the internal routing in review has changed
     */
    reviewRouteChangesPollInterval: 500,

    /**
     * Keep Author session alive
     */
    authorTokenRefreshTime: 4 * 60 * 1000, // 4 minutes

    /**
    * Keep Files session alive
    */
    filesTokenRefreshTime: 4 * 60 * 1000, // 4 minutes

    /**
     * Default timeout for loading spinner, to ensure it doesnt just persist over the top
     * TODO confirm if this is needed and for how long
     */
    loadingSpinnerTimeout: 30 * 60 * 1000, // 30 seconds, overridden by config.json file
    /**
     * 400 ms is the default animation duration on dialog show/hide
     * this is also used as the timeout for clearing complete review dialog data, so it waits until after the dialog is hidden
     */
    dialogAnimationDuration: 400,

    secureCookieAttribute: 'true', // overridden by config.json file
    sameSiteCookieAttribute: 'Strict', // overridden by config.json file

    rdpPasswordApiCallTimeout: 30 * 1000, // 30 seconds
  };

  private http: HttpClient;

  constructor(httpHandler: HttpBackend, private utils: UtilService) {
    this.http = new HttpClient(httpHandler);
  }
  async loadConfig(): Promise<void> {
    const data = await this.http
      .get<ConfigData>('config/config.json')
      .toPromise();
    let hostUrl = this.utils.window().location.protocol + '//' + this.utils.window().location.hostname + '/';
    // If Portal is running on localhost, use APPLICATIONSBASEURL as the base URL for
    // all applications and the API. This would normally be set to aitdev.author-it.com
    // Use this along with the custom fiddler script to enable local development.
    // See: https://authorit.atlassian.net/wiki/spaces/CLAS/pages/2428370965/How+to+test+Portal+locally
    if (this.utils.window().location.hostname === 'localhost') {
      hostUrl = data.APPLICATIONSBASEURL;
    }
    this.baseUrl = data.APPLICATIONSBASEURL;
    this.authorRdpUrl = data.AUTHOR_RDP_URL;
    this.sparkGateway = data.SPARK_GATEWAY;
    this.sparkServer = data.SPARK_SERVER;
    this.sparkDomain = data.SPARK_DOMAIN;
    this.loggingLevel = data.LOGGING_LEVEL;
    this.gtmToken = data.GTM_TOKEN;
    this.newAccountUrl = data.CREATE_NEW_ACCOUNT_URL;
    this.userflowToken = data.USERFLOW_TOKEN;
    this.aitApiEndpoint = `${hostUrl}${this.default.url.aitApiEndpoint}`;
    this.libraryUrl = `${hostUrl}${this.default.url.library}`;
    this.publishingUrl = `${hostUrl}${this.default.url.publishing}`;
    this.reviewsUrl = `${hostUrl}${this.default.url.reviews}`;
    this.signoffUrl = `${hostUrl}${this.default.url.signoff}`;
    this.translationsUrl = `${hostUrl}${this.default.url.translations}`;
    this.filesUrl = `${hostUrl}${this.default.url.files}`;
    this.authorUrl = `${hostUrl}${this.default.url.author}`;
    this.settingsUrl = `${hostUrl}${this.default.url.settings}`;
    this.profileUrl = `${hostUrl}${this.default.url.profile}`;
    this.recentbookUrl = `${hostUrl}${this.default.url.recentBook}`;
    this.featureFlags = data.FEATURE_FLAGS;
    this.default.loadingSpinnerTimeout = data.LOADING_SPINNER_TIMEOUT;
    this.default.secureCookieAttribute = data.SECURE_COOKIE_ATTRIBUTE;
    this.default.sameSiteCookieAttribute = data.SAMESITE_COOKIE_ATTRIBUTE;

    (window as any).getConfig = () => {
      return {
        baseUrl: this.baseUrl,
        authorRdpUrl: this.authorRdpUrl,
        sparkGateway: this.sparkGateway,
        sparkServer: this.sparkServer,
        sparkDomain: this.sparkDomain,
        loggingLevel: this.loggingLevel,
        userflowToken: this.userflowToken,
        gtmToken: this.gtmToken,
        newAccountUrl: this.newAccountUrl,
        aitApiEndpoint: this.aitApiEndpoint,
        libraryUrl: this.libraryUrl,
        publishingUrl: this.publishingUrl,
        reviewsUrl: this.reviewsUrl,
        signoffUrl: this.signoffUrl,
        translationsUrl: this.translationsUrl,
        filesUrl: this.filesUrl,
        authorUrl: this.authorUrl,
        settingsUrl: this.settingsUrl,
        profileUrl: this.profileUrl,
        recentbookUrl: this.recentbookUrl,
        default: this.default,
      };
    };
  }
}
