import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { CookieService } from '../cookie/cookie.service';
import { UtilService } from '../util/util.service';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private utils: UtilService,
    private cookieService: CookieService
  ) { }

  public async canActivate(
    _route: ActivatedRouteSnapshot,
    { url }: RouterStateSnapshot
  ): Promise<boolean|UrlTree> {
    // reset password should always be accessible for non sso users
    if (url.toLowerCase().startsWith('/login/resetpassword') && !this.authService.ssoDomain) {
      return true;
    }

    // authenticated
    if (this.authService.isAuthenticated()) {
      // library info not yet loaded (multi-library users must select which library to use)
      if (!this.authService.getLoadedLibraryAlias()) {
        // if there is a saved redirect url set it for redirecting after selecting library, using login flow
        this.authService.getSSORedirectUrl();

        if (url.toLowerCase() === '/login/selectlibrary') {
          return true;
        }
        return this.router.createUrlTree(['Login/SelectLibrary']);
      }
      // library info has been loaded
      else {
        // navigating to login or library select
        if (url.toLowerCase() === '/login' || url.toLowerCase() === '/login/selectlibrary') {
          // redirect to Home
          return this.router.createUrlTree(['Home']);
        }

        // if there is a saved redirect url, redirect to it now
        // Note: redirect is handled by login flow for multi-library sso users, so this will return empty to prevent a redirect loop
        const ssoRedirect = this.authService.getSSORedirectUrl();
        if (!!ssoRedirect) {
          return this.router.createUrlTree(['Redirect'  + ssoRedirect]);
        }

        // check permissions
        // If data.feature exists on a route, check that the user has permission to access
        if (_route.data?.feature) {
          return this.authService.hasPermission(_route.data.feature) || this.router.createUrlTree(['Error', '401']);
        }
        // If data.feature does not exist, user has access
        return true;
      }
      // Unauthenticated
    } else {
      // coming from sso url
      if (!!this.authService.ssoDomain) {
        // clear library to force library selection after SSO authentication (sets again after auth if user only has 1 library)
        this.authService.deleteLibraryCookie();

        // store current url for redirecting back to after sso authentication
        this.authService.setSSORedirectUrl(url);
        this.authService.navToSSOLogin();
        return false;
      }
      // not sso user
      else {
        if (url.toLowerCase() === '/login') {
          // allow direct nav to login
          return true;
        }

        if (url.toLowerCase() === '/selectlibrary' && this.authService.pendingLibrarySelection) {
          // allow direct nav to select library
          return true;
        }

        // Skip if going direct to Login or SelectLibrary with no route params, or going to ResetPassword route (has 'reset' route param that we dont want to store)
        if (url.toLowerCase() !== '/login'
          && url.toLowerCase() !== '/login/selectlibrary'
          && !url.toLowerCase().startsWith('/login/resetpassword')) {
          // Remember the original URL or redirect query params -- handled by auth service after successful authentication
          this.authService.redirectUrl = url;
        }
        // unathenticated, not sso user, so go to login
        return this.router.createUrlTree(['Login']);
      }
    }
    return false;
  }
}
