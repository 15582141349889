
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HomePageService } from 'src/app/services/home-page/home-page.service';
import { ResourceService } from 'src/app/services/resource/resource.service';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { KanbanCardData } from 'src/app/models/kanban-card-data.model';
import { ConfigService } from 'src/app/services/config/config.service';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util/util.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  /**
   * Card data for displaying on the kanban
   */
  public cardItems: KanbanCardData[] = [];

  public columns = {
    recent: 'recent',
    import: 'import',
    create: 'create',
    review: 'review',
    translate: 'translate',
    publish: 'publish',
  };
  private pollForRecentItems = null;

  /**
   * Information for displaying a subscription warning if the expiry date is pending or past
   */
  public shouldShowSubscriptionWarning = false;
  public shouldShowSubscriptionCriticalWarning = false;
  public subscriptionWarningTitle = '';
  public subscriptionWarningMessage = '';
  public subscriptionWarningCallToAction = '';

  constructor(
    private homePageService: HomePageService,
    private configService: ConfigService,
    public resources: ResourceService,
    private featureService: FeatureService,
    private authService: AuthenticationService,
    private router: Router,
    private utils: UtilService
  ) {}

  ngOnInit(): void {
    this.getStaticItems();
    this.getRecentItems();
    this.pollForRecentItems = setInterval(
      () => this.getRecentItems(),
      this.configService.default.homePageRecentItemsPollInterval
    );
    this.prepareSubscriptionWarning();
  }

  prepareSubscriptionWarning() {
    if (this.authService.hasPermission('subscription-warning')) {
      // Expiry date
      const expiryDate = new Date(this.authService.getUserProperty('subscriptionExpiry'));
      const expiryDateStr = this.utils.formatDate(expiryDate, this.configService.default.subscriptionExpiryDateFormat);
      let daysUntilExpiry = this.utils.daysBetweenDates(new Date(), expiryDate);

      // Grace period
      const graceDate = new Date(expiryDate);
      graceDate.setDate(graceDate.getDate() + this.configService.default.subscriptionExpiryGraceDays);
      const graceDateStr = this.utils.formatDate(graceDate, this.configService.default.subscriptionExpiryDateFormat);
      let daysUntilGraceExpiry = this.utils.daysBetweenDates(new Date(), graceDate);

      this.shouldShowSubscriptionWarning = daysUntilExpiry < this.configService.default.subscriptionExpiryWarningDays;
      this.shouldShowSubscriptionCriticalWarning = daysUntilExpiry < this.configService.default.subscriptionExpiryCriticalWarningDays;

      if (this.shouldShowSubscriptionWarning) {
        if (daysUntilExpiry === 0) {
          this.subscriptionWarningTitle = this.resources.localisedStrings.subscriptionExpiryWarningToday;
          this.subscriptionWarningMessage = this.resources.localisedStrings.subscriptionExpiryWarningMessageToday;
        }
        else if (daysUntilExpiry < 0) {
          this.subscriptionWarningTitle = this.resources.localisedStrings.subscriptionExpiryWarningPast;
          this.subscriptionWarningMessage = this.resources.localisedStrings.subscriptionExpiryWarningMessagePast;
          daysUntilExpiry = -daysUntilExpiry;
        }
        else {
          this.subscriptionWarningTitle = this.resources.localisedStrings.subscriptionExpiryWarningFuture;
          this.subscriptionWarningMessage = this.resources.localisedStrings.subscriptionExpiryWarningMessageFuture;
        }
        this.subscriptionWarningCallToAction = this.resources.localisedStrings.subscriptionExpiryWarningCallToAction;

        this.subscriptionWarningTitle = this.subscriptionWarningTitle.replace('{days}', daysUntilExpiry.toString());
        this.subscriptionWarningTitle = this.subscriptionWarningTitle.replace('{expiryDate}', expiryDateStr);
        this.subscriptionWarningMessage = this.subscriptionWarningMessage.replace('{days}', daysUntilExpiry.toString());
        this.subscriptionWarningMessage = this.subscriptionWarningMessage.replace('{expiryDate}', expiryDateStr);
        this.subscriptionWarningMessage = this.subscriptionWarningMessage.replace('{daysGrace}', daysUntilGraceExpiry.toString());
        this.subscriptionWarningTitle = this.subscriptionWarningTitle.replace('{graceDate}', graceDateStr);
      }
    }
  }

  ngOnDestroy() {
    if (this.pollForRecentItems) {
      clearInterval(this.pollForRecentItems);
      this.pollForRecentItems = null;
    }
  }

  /**
   * Handle external and internal url routing on card link click
   * @param event default click event
   * @param url url to navigate to
   */
  public onCardLinkClick(event: PointerEvent, url: string): void {
    event.preventDefault();
    if (!url || !url.trim()) {
      return;
    }

    if (url.startsWith('https://') || url.startsWith('http://')) {
      // external link, open in new tab
      this.utils.window().open(url, '_blank');
    } else {
      // navigate within application
      this.router.navigate([url]);
    }
  }

  /**
   * User has clicked on link that subscription is expiring soon
   */
  public onSubscriptionExpiryLinkClick(event: PointerEvent): void {
    event.preventDefault();
    this.utils.window().open(this.configService.newAccountUrl, '_blank');
  }

  /**
   * Checks for duplicates based on the id, and adds unique cards to the kanban
   * @param newCards cards to add
   * @param replaceColumn
   * @returns
   */
  private addCardsToKanban(newCards: KanbanCardData[], replaceColumn?: string): void {
    // are there cards to add
    if (!newCards || newCards.length === 0) {

      if (!!replaceColumn && !!replaceColumn.trim()) {
        // no cards to add, but we want to replace a column, so clear it.
        this.cardItems = [...this.cardItems.filter((card) => card.column !== replaceColumn)];
      }
      return;
    }

    const currentCards: KanbanCardData[] = [];
    if (!!replaceColumn && !!replaceColumn.trim()) {
      // we want to replace all cards in a column, so filter existing ones out
      currentCards.push(...this.cardItems.filter((card) => card.column !== replaceColumn));
    } else {
      currentCards.push(...this.cardItems);
    }

    // prevent possible duplicate cards, replace any existing with new
    this.cardItems = [...currentCards.filter((card) => !newCards.find((item) => item.id === card.id)), ...newCards];
  }

  /**
   * Get recent items
   */
  private async getRecentItems() {
    const publishingJobs = await this.homePageService.getRecentPublishingJobs();

    if (publishingJobs && publishingJobs.length > 0) {
      // dont want leading /
      const recentBookBaseUrl = this.featureService.getFeatureByFeatureName('recentbook').route.slice(1);

      // map publishing job to a kanban card
      this.addCardsToKanban(publishingJobs.map<KanbanCardData>(
        (item) => {
          return {
            id: 'pub-job-' + item.jobId,
            column: this.columns.recent,
            title: item.bookDescription,
            summary: this.resources.localisedStrings.submitted + ': '
              + this.utils.formatDate(new Date(item.submittedTime), this.configService.default.recentItemsDateTimeFormat),
            buttonText: this.resources.localisedStrings.edit,
            buttonIcon: 'recentBook',
            cardClass: '',
            url: recentBookBaseUrl + '/' + item.bookId,
            subtitle: 'outputFormat' + item.format,
          };
        })
      );
    }
  }

  /**
   * Get static card items
   */
  private async getStaticItems() {
    const otherItems = await this.homePageService.getOtherItems();
    if (otherItems && otherItems.length > 0) {
      this.addCardsToKanban(otherItems.map<KanbanCardData>(
        (item, index) => {
          return {
            id: 'static-' + index,
            column: item.column,
            title: this.resources.localisedStrings[item.title],
            summary: this.resources.localisedStrings[item.summary],
            buttonText: this.resources.localisedStrings[item.buttonText],
            buttonIcon: item.buttonIcon,
            cardClass: item.cardClass,
            url: item.url,
          };
        })
      );
    }
  }

}
