<div class="kanban-outer">
  <div id="columns.recent" class="kanban-column">
    <h2>{{resources.localisedStrings.recentColumnTitle}}</h2>
    <p-card class="kanban-card featured" [class.critical]="shouldShowSubscriptionCriticalWarning"
      [header]="subscriptionWarningTitle" *ngIf="shouldShowSubscriptionWarning">
      <p [innerHTML]="subscriptionWarningMessage"></p>
      <p class="kanban-footer">
        <a href="#" (click)="onSubscriptionExpiryLinkClick($event)">
          <span>{{subscriptionWarningCallToAction}}</span>
          <app-icon iconName="linkExternal"></app-icon>
        </a>
      </p>
    </p-card>
    <ng-container *ngFor="let card of cardItems">
      <ng-container *ngIf="card.column === 'recent'">
        <ng-container *ngTemplateOutlet="cardSettingsTemplate; context: { $implicit: card }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div id="columns.import" class="kanban-column">
    <h2>{{resources.localisedStrings.importColumnTitle}}</h2>
    <ng-container *ngFor="let card of cardItems">
      <ng-container *ngIf="card.column === 'import'">
        <ng-container *ngTemplateOutlet="cardSettingsTemplate; context: { $implicit: card }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div id="columns.create" class="kanban-column">
    <h2>{{resources.localisedStrings.createColumnTitle}}</h2>
    <ng-container *ngFor="let card of cardItems">
      <ng-container *ngIf="card.column === 'create'">
        <ng-container *ngTemplateOutlet="cardSettingsTemplate; context: { $implicit: card }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div id="columns.review" class="kanban-column">
    <h2>{{resources.localisedStrings.reviewColumnTitle}}</h2>
    <ng-container *ngFor="let card of cardItems">
      <ng-container *ngIf="card.column === 'review'">
        <ng-container *ngTemplateOutlet="cardSettingsTemplate; context: { $implicit: card }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div id="columns.translate" class="kanban-column">
    <h2>{{resources.localisedStrings.translateColumnTitle}}</h2>
    <ng-container *ngFor="let card of cardItems">
      <ng-container *ngIf="card.column === 'translate'">
        <ng-container *ngTemplateOutlet="cardSettingsTemplate; context: { $implicit: card }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div id="columns.publish" class="kanban-column">
    <h2>{{resources.localisedStrings.publishColumnTitle}}</h2>
    <ng-container *ngFor="let card of cardItems">
      <ng-container *ngIf="card.column === 'publish'">
        <ng-container *ngTemplateOutlet="cardSettingsTemplate; context: { $implicit: card }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #cardSettingsTemplate let-data>
    <p-card class="kanban-card {{data.cardClass}}" header="{{data.title}}" [subheader]="resources.localisedStrings[data.subtitle] ?? null">
      <p>{{data.summary}}</p>
      <p class="kanban-footer">
        <a [href]="data.url || '#'" (click)="onCardLinkClick($event, data.url)">
          <span>{{data.buttonText}}</span>
          <app-icon [iconName]="data.buttonIcon"></app-icon>
        </a>
      </p>
    </p-card>
  </ng-template>
</div>